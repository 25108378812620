import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Green Pitch
			</title>
			<meta name={"description"} content={"Там, де кожен удар починає історію - The Green Pitch, ваш футбольний притулок у самому серці міста"} />
			<meta property={"og:title"} content={"Головна | Green Pitch"} />
			<meta property={"og:description"} content={"Там, де кожен удар починає історію - The Green Pitch, ваш футбольний притулок у самому серці міста"} />
			<meta property={"og:image"} content={"https://ua.pixvos.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.pixvos.com/img/946916.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.pixvos.com/img/946916.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.pixvos.com/img/946916.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.pixvos.com/img/946916.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.pixvos.com/img/946916.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.pixvos.com/img/946916.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 0 0" quarkly-title="Hero-18">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					lg-width="100%"
					display="flex"
					flex-direction="column"
					lg-padding="0px 0 0px 0px"
					lg-margin="0px 0px 50px 0px"
					margin="0px 0px 80px 0px"
					align-items="center"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 700 64px/1.2 --fontFamily-sans"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						padding="0px 250px 0px 250px"
						text-align="center"
						lg-padding="0px 0 0px 0"
						color="#111"
					>
						Green Pitch
					</Text>
					<Text
						margin="0px 0px 50px 0px"
						font="--lead"
						color="#111"
						text-align="center"
						padding="0px 30px 0px 30px"
						lg-padding="0px 0 0px 0"
						lg-margin="0px 0px 30px 0px"
					>
						Тут трава завжди зелена, а гра завжди в розпалі. Green Pitch - це місце для товариського матчу, інтенсивних тренувань або простого тренування. Наше єдине, незаймане футбольне поле чекає на гравців усіх рівнів з розпростертими обіймами та відкритими воротами.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
						sm-align-self="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="--color-primary"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--color-primary"
							hover-color="#111"
							hover-background="rgba(82, 46, 224, 0)"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Звʼязатися
						</Button>
					</Box>
				</Box>
				<Image
					src="https://ua.pixvos.com/img/1.jpg"
					background="--color-lightD1"
					border-radius="15px 15px 0 0"
					height="450px"
					object-fit="cover"
					object-position="0 70%"
					lg-height="300px"
					md-height="200px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box>
				<Text
					as="h2"
					font="--headline2"
					md-font="--headline2"
					margin="20px 0 0 0"
					text-align="center"
				>
					Відкрийте для себе досконалість
				</Text>
				<Text as="p" font="--lead" margin="20px 50px 80px" text-align="center">
					Виходьте на наше поле та відчуйте азарт гри. Наше доглянуте поле нормативного розміру створено для оптимальної гри, незалежно від того, забиваєте ви голи чи захищаєтесь від них. Потрібна екіпіровка? Орендуй його тут. Шукаєте поради? Наші досвідчені тренери завжди готові покращити вашу гру.
				</Text>
			</Box>
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 40px"
				text-align="center"
			>
				Чим Green Pitch вирізняється з-поміж інших
			</Text>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						1
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Центральне розташування
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						До нас легко дістатися, ми знаходимося саме там, де вам потрібно для спонтанної гри або запланованого командного тренування.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						2
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Якісне покриття
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Наше штучне покриття преміум-класу доглянуте для стабільної гри, без бруду, без калюж, лише чистий футбол.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						3
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Вечірнє освітлення
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Наше поле освітлюється для нічних ігор, тому веселощі не закінчуються із заходом сонця.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-primary"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						4
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Ексклюзивне використання
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Коли ви бронюєте у нас, поле у вашому розпорядженні - ніяких перекриттів, ніяких перерв.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-3">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="65%"
				align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				justify-content="center"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					height="auto"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://ua.pixvos.com/img/2.jpg"
						object-fit="cover"
						left={0}
						top={0}
						right={0}
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box
					transform="translateY(0px)"
					width="100%"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						bottom={0}
						src="https://ua.pixvos.com/img/3.jpg"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Green Pitch - де грає громада і розпалюються пристрасті
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});